<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <Spinner v-if="isLoading"></Spinner>
        <template v-if="!isLoading && userData">
          <Toolbar class="p-mb-4">
            <template #start>
              <div style="font-size: 1.4em; color: inherit">{{ $t('Employee') }}:
                <span v-if="userData">
<!--                  <span v-if="userData.type === constants.userTypes.legal_entity && userData.company_name">{{ userData.company_name }}</span>-->
                  <span>{{ userData.first_name }} {{ userData.last_name }}</span>
                </span>
              </div>
            </template>
            <template #end>
              <template v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">
                <Button v-if="userData.employee?.status === 1"  :disabled="disableEditButton" @click="openConfirmModal" label="To archive" icon="ti-archive" class="p-button-secondary p-button-raised p-mr-2"/>
                <Button v-else-if="userData.employee?.status === 2"  :disabled="disableEditButton" @click="openConfirmModal" label="Return from archive" icon="ti-archive" class="p-button-info p-button-raised p-mr-2"/>
              </template>
<!--              <Button @click="fireEmployeeOnClick" icon="ti-close" class="p-button-warning p-button-raised p-mr-2" label="Fire"/>-->
              <Button v-show="userData && checkUserCanEditCustomer(userData.role)"
                      :disabled="disableEditButton"
                      label="Edit employee"
                      icon="ti-pencil"
                      @click="editItem"
                      class="p-button-success p-button-raised"/>
            </template>

            4214214
          </Toolbar>

          <TabMenu :model="tabMenuItems" class="p-p-1"/>
          <router-view @update-items="updateItems" :user="userData"/>

          <EmployeeModal :visible="itemModal"
                         :item="item"
                         @update-items="updateItems"
                         @close="closeItemModal">
          </EmployeeModal>

<!--          <FiringEmployeeModal @close="closeFiringEmployeeModal"/>-->

<!--          <FiringEmployeeModal :visible="firingEmployeeModal"-->
<!--                                :employee="userData"-->
<!--                                @update-items="updateItems"-->
<!--                                @close="closeFiringEmployeeModal"/>-->
        </template>
        <div v-else-if="!employeeLoadingStarted && !userData" class="p-text-center p-m-2 p-p-6">{{ $t('No data available') }}</div>
			</div>

      <ConfirmModal :visible="confirmModal"
                    @confirm="confirmEmployeeAction"
                    @close="closeConfirmModal"/>
		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import settings from '@/settings.js'
import constants from '@/constants'
import EmployeeModal from '@/pages/users/components/EmployeeModal'
import permissionsMixins from '@/mixins/permissionsMixins'
import formatMixins from '@/mixins/formatMixins'
import httpMixins from "@/mixins/httpMixins";

export default {
  mixins: [ permissionsMixins, formatMixins, httpMixins ],
  components: { EmployeeModal },
	data() {
		return {
      disableEditButton: false,
      confirmModal: false,
      firingEmployeeModal: false,
      employeeLoadingStarted: false,
      itemModal: false,
      item: {},
      taxes: [],
      userId: null,
      activeIndex: '',
      isLoading: true,
      userData: null,
      settings,
      tabMenuItems: [],
      constants
    }
	},
  watch: {
    '$route'() {
      const route = this.$route.path.split('/')
      if (this.$route.path === '/login' || route.indexOf('employees') === -1 || route.indexOf(this.$route.params.id) === -1) {
        return false
      }

      if (!this.userData) {
        if (this.$route.params.id) {
          this.getUserData()
        }
      } else {
        if (this.$route.params.id && +this.$route.params.id !== this.userData.id) {
          this.getUserData()
        }
      }
    },
    '$i18n.locale'() {
      if (this.userData) {
        this.getTabMenu()
      } else {
        if (!this.employeeLoadingStarted && this.$route.params.id) {
          this.getUserData()
        }
      }
    },
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        // if (this.itemModal) {
        //   this.closeItemModal()
        // }
        // if (this.confirmItemDeleteModal) {
        //   this.closeConfirmItemDeleteModal()
        // }
        // if (this.firingEmployeeModal) {
        //   this.closeFiringEmployeeModal()
        // }
        if (this.confirmModal) {
          this.closeConfirmModal()
        }
        if (this.itemModal) {
          this.closeItemModal()
        }
      }
    },
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    if (!this.$route.params.id) return false
    this.getUserData()
	},
  methods: {
    async confirmEmployeeAction() {
      const obj = {
        id: this.userData.employee?.id,
      }

      try {
        const { status, data } = await httpClient.post(`employee/change-status`, obj)
        if (status === 200 && data?.success) {
          this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: this.settings.toastLife});

          this.closeConfirmModal()
          this.getUserData()
        } else if (data?.error) {
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
        }
      } catch(err) {
        this.showError(err)
      }
      // finally {
      //   this.disableEditButton = false
      // }
      // this.confirmModal = true
      // this.$store.commit('toggleFirstLayer', true)
    },
    openConfirmModal() {
      this.confirmModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeConfirmModal() {
      this.confirmModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    // fireEmployeeOnClick() {
    //   this.firingEmployeeModal = true
    //   this.$store.commit('toggleFirstLayer', true)
    // },
    // closeFiringEmployeeModal() {
    //   this.firingEmployeeModal = false
    //   this.$store.commit('toggleFirstLayer', false)
    // },
    editItem() {
      if (this.userData) {
        this.item = this.userData
        this.itemModal = true
        this.$store.commit('toggleFirstLayer', true)
      }
    },
    updateItems() {
      if (this.$route.params.id) {
        this.getUserData(false)
      }
    },
    closeItemModal() {
      this.itemModal = false
      if (this.$store.state.firstLayerIsOpened === true ) {
        this.$store.commit('toggleFirstLayer', false)
      }
      this.item = {}
      this.submitted = false
    },
    getTabMenu() {
      if (this.userData?.role === 11 || this.userData?.role === 13) {
        this.tabMenuItems = [
          {label: this.$t('General information'), icon: 'ti-user', to: `/employees/${this.$route.params.id}/overview`},
          {label: this.$t('Salary settings'), icon: 'ti-settings', to: `/employees/${this.$route.params.id}/salary-settings`},
          {label: this.$t('Payments'), icon: 'ti-money', to: `/employees/${this.$route.params.id}/payments`},
        ]
      } else {
        this.tabMenuItems = [
          {label: this.$t('General information'), icon: 'ti-user', to: `/employees/${this.$route.params.id}/overview`},
          {label: this.$t('Payments'), icon: 'ti-money', to: `/employees/${this.$route.params.id}/payments`},
        ]
      }

    },
    async getUserData(showSpinner = true) {
      this.isLoading = showSpinner
      this.disableEditButton = true
      this.employeeLoadingStarted = true
      try {
        const { data, status } = await httpClient.post('user/get-user-by-id?expand=creator,tax,employee,address', { id: this.$route.params.id })
        if (status === 200 && data) {
          this.userData = data

          // console.log(data)
          this.getTabMenu()
          // this.getTaxes()
        } else {
          this.userData = null
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.employeeLoadingStarted = false
        this.isLoading = false
        this.disableEditButton = false
      }
    },
    // async getTaxes() {
    //   try {
    //     const { data, status } = await httpClient('tax/get-taxes')
    //     if (status === 200 && data) {
    //       this.taxes = data
    //     }
    //   } catch(err) {
    //     this.showError(err)
    //   }
    // },
	},
  computed: {
    itemToDeleteData() {
      return 'delete data'
    },
  }
}
</script>

<style scoped lang="scss">
.datatable-wrapper {
  overflow-y: auto;
  padding-bottom: 25px;
}

.positions-table {
  border-collapse: collapse;
  width: 100%;
  tr {
    td {
      padding: 8px !important;
    }
  }
}
</style>